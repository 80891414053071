.folder {
    width:100%;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-flow:wrap;
}

.folder_info {
    width:100%;
    font-size: 1em;
    color: white;
    text-align: left;
    display: flex;
    align-items: stretch;
    border: 1px white solid;
    justify-content:first baseline;
    gap: 10px;
    padding:10px;
    align-items: center;
}

.folder_info_open {
    width:100%;
    font-size: 1em;
    color: black;
    background-color: white;
    text-align: left;
    display: flex;
    align-items: stretch;

    justify-content:first baseline;
    gap: 10px;
    padding:10px;
    align-items: center;
    position: sticky;
    top: 50px;
}

.folder_info_root {
    border-left:7px white solid;
}

.folder_info_not_root {
    margin-left:7px;
}

.folder_name {
    color: inherit;
}

.folder_container {
    width:100%;
    min-height: 0px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-flow:wrap;
    gap: 5px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.folder_icon {
    width: 20px;
    height: 20px;
}