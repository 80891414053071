.product {
    width:100%;
    color: white;
    text-align: left;
    display: flex;
    gap: 5px;
    border-top: 1px white solid;
    padding:10px;
    flex-direction:column;
    justify-content: left;
    flex-flow:column;
}

.product_name {
    color: white;
    font-size: 1em;
    font-weight: bold;
}

.product_info {
    color: white;
    text-align: left;
    display: flex;
    gap: 0px;
    padding:0px;
    margin-left: 0px;
    flex-direction:row;
    justify-content: left;
    flex-flow:column;
}
.product_count {
    color: rgb(216, 214, 214);
    font-size: 0.8em;
}

.product_cast {
    color: rgb(216, 214, 214);
    font-size: 0.8em;
}