.footer {
    background-color: #9F9C9D;
    width:100vw;
    height:50px;
    position:relative;
    bottom:0px;
    left:0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    z-index: 9999;
    padding: 15px;
}

.ooo {
    font-size: 0.6em;
}

