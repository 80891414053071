@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: DINPro;
  font-weight: bold;
  src: url("../fonts/DINPro-Bold\ Regular.otf") format("opentype");
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body {
  background-color: #3E3E45;
  color:white;
  font-family:DINPro;
}

a:visited {
  color: white;
}

a {
  text-decoration: none;
}

#root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-flow:wrap;
}

.mp_container {
    box-sizing: border-box;
    margin-left:auto;
    margin-right: auto;
    width:1024px;
    min-height: calc(100vh-50px-50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:5px;
    padding-top: 30px;
    padding-bottom:10px;
    margin-top: 50px;
}

.mp_container_small {
    width:100%;
    box-sizing: border-box;
    margin-left:auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:15px;
    padding-bottom:20px;
    margin-top: 50px;
}

.mp_container_info{
  width:100%;
  font-size: 0.8em;
  color: white;
  text-align: left;
  display: flex;
  align-items: stretch;
  border: 1px red solid;
  justify-content:first baseline;
  gap: 10px;
  padding:10px;
  align-items: center;
  border-left:7px red solid;
}

.mp_container_small iframe {
    width: 375px;
    height: 250px;
}

.header, .row {
    display: flex;
}

.col {
    border:1px white solid;
    justify-content: center;
    padding: 5px;
    flex: 1;
}

.images_w {
  width:100%;
}

.navbar_tel {
  color: white;
  font-size: 0.70em;
  text-decoration: none;
}

@media screen and (max-width: 1640px) {
    .mp_container {
        width:1024px;
    }
}

@media screen and (max-width: 1240px) {
    .mp_container {
        width:90%;
    }
}

@media screen and (max-width: 720px) {
    .mp_container {
        width:95%;
    }
}

