.navbar {
    background-color: #9F9C9D;
    width:100vw;
    height: 50px;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position:fixed;
    left:0px;
    top:0px;
    z-index: 9999;
}

.navbar_left {
    margin:10px;
    height: 35px;
    color: white;
    flex-flow: row;
    display: flex;
    align-items: center;
}

.navbar_right {
    margin:10px;
    height: 35px;
    color: white;
    flex-flow: row;
    display: flex;
    align-items: center;
    gap:10px;
    padding-right: 15px;
}

.navbar_Logo {
    margin-left:5px;
    height: 35px;
}

.navbar_wa {
    width:30px;
    height:30px;
    margin-right: 10px;
}

.navbar_tg {
    width:31px;
    height:31px;
    margin-right: 10px;
}

.navbar_ph {
    width:28px;
    height:28px;
    margin-right: 10px;
}

.navbar_pg {
    width:28px;
    height:28px;
    margin-right: 10px;
}

.navbar_tel {
    font-size: 0.70em;
    text-decoration: none;
}

@media screen and (min-width: 750px) {
    .navbar_tel {
        font-size: 1.2em;
    }
}

.dropdown_menu {
    width:40px;
    height:40px;
    position: relative;
    z-index: 10000;
}

.dropdown_menu_toggle {
    width:40px;
    height:40px;
    z-index: 10000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}

.dropdown_menu_toggle::before {
    content: '';
    position: absolute;
    width:30px;
    height:2px;
    background-color: white;
    transition: 0.2s;
    transform: translateY(-10px);
    box-shadow: 0 10px 0 white;
}

.dropdown_menu.active.active .dropdown_menu_toggle::before {
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 0 0 black;
}

.dropdown_menu_toggle::after {
    content: '';
    position: absolute;
    width:30px;
    height:2px;
    background-color: white;
    transition: 0.2s;
    transform: translateY(10px);
}

.dropdown_menu.active.active .dropdown_menu_toggle::after {
    transform: translateY(0px) rotate(-45deg);
    box-shadow: 0 0 0 black;
}

.dropdown_menu_section {
    width:250px;
    display: flex;
    flex-flow: column;
    gap:5px;
    position:absolute;
    right:0px;
    visibility:hidden;
    background-color: white;
    box-shadow: 0 10px 10px rgba(255,255,255,0.3);
    color:black;
    border-radius: 10px;
    overflow: hidden;
}

.dropdown_menu.active .dropdown_menu_section {
    visibility:visible;
}

.dropdown_menu_section a {
    display: block;
    width:100%;
    font-size: 1em;
    padding:12px 15px;
    cursor: pointer;
    align-items:center;
    justify-items: center;
}

.dropdown_menu_section a img {
    vertical-align:middle;
}

.dropdown_menu_section a:hover {
    background-color: #9F9C9D;
}


.dropdown_menu_section a, .dropdown_menu_section a:visited {
    color: black;
}
